import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Dialog,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

// Service data with titles, descriptions, and background images
const services = [
  { 
    title: 'Wedding Receptions', 
    description: 'Eleganance for your special day.', 
    images: ['/assets/weds.jpg', '/assets/intro.jpg'] 
  },
  { 
    title: 'Introduction Receptions', 
    description: 'Beautifully crafted setups for introductions.', 
    images: ['/assets/deco.jpg', '/assets/decor.jpg'] 
  },
  { 
    title: 'Anniversary Parties', 
    description: 'Celebrate milestones with style.', 
    images: ['/assets/anniversary2.jpg', '/assets/5.jpg'] 
  },
  { 
    title: 'Birth Day Parties', 
    description: 'Sturdy and customizable  setups.', 
    images: ['/assets/stage4.jpg', '/assets/stage3.jpg'] 
  },
  { 
    title: 'Hide Out Parties', 
    description: 'Quality  solutions for events.', 
    images: ['/assets/5.jpg', '/assets/conf1.jpg'] 
  },
  { 
    title: 'Concerts', 
    description: 'Quality Work', 
    images: ['/assets/chairs.jpeg', '/assets/chairsandtables.jpg'] 
  },

];

const Services = () => {
  const [open, setOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Zoom State
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = useState(null);

  // Open modal and set the selected images
  const handleOpen = (images) => {
    setCurrentImages(images);
    setCurrentImageIndex(0); // Start with the first image
    setOpen(true);
  };

  // Close the modal and reset zoom
  const handleClose = () => {
    setOpen(false);
    setScale(1);
    setTranslate({ x: 0, y: 0 });
  };

  // Navigate to the next image
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
    resetZoom();
  };

  // Navigate to the previous image
  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex - 1 + currentImages.length) % currentImages.length
    );
    resetZoom();
  };

  // Reset zoom state
  const resetZoom = () => {
    setScale(1);
    setTranslate({ x: 0, y: 0 });
  };

  // Zoom with pinch or mouse wheel
  const handleZoom = (e) => {
    e.preventDefault();
    const zoomFactor = e.deltaY < 0 ? 0.1 : -0.1;
    setScale((prevScale) => Math.max(1, prevScale + zoomFactor));
  };

  // Handle drag start
  const handleDragStart = (e) => {
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  // Handle dragging
  const handleDrag = (e) => {
    if (dragStart) {
      setTranslate((prev) => ({
        x: prev.x + e.clientX - dragStart.x,
        y: prev.y + e.clientY - dragStart.y,
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  // End dragging
  const handleDragEnd = () => {
    setDragStart(null);
  };

  return (
    <Box id='events' sx={{ padding: '60px 20px', backgroundColor: '#f8f8f8' }}>
      {/* Main Heading */}
      <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 2, color: '#d4af37' }}>
        Some of Our Work
      </Typography>
      {/* Subheading */}
      <Typography variant="h5" align="center" sx={{ mb: 4, color: '#555' }}>
        Check Out Our Catalog
      </Typography>

      {/* Services Grid */}
      <Grid container spacing={3} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => handleOpen(service.images)}
              sx={{
                height: '400px',
                textAlign: 'center',
                borderRadius: 2,
                boxShadow: 3,
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
                backgroundImage: `url(${process.env.PUBLIC_URL + service.images[0]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#fff',
                transition: 'transform 0.3s ease-in-out',
              }}
            >
              <CardContent
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#d4af37' }}>
                  {service.title}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog Modal */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100vh',
            backgroundColor: '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
          onWheel={handleZoom}
          onMouseDown={handleDragStart}
          onMouseMove={handleDrag}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Navigation Buttons */}
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 10,
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#fff',
              transform: 'translateY(-50%)',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 10,
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#fff',
              transform: 'translateY(-50%)',
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>

          {/* Full-Screen Image */}
          <img
            src={currentImages[currentImageIndex]}
            alt="Selected Service"
            style={{
              transform: `scale(${scale}) translate(${translate.x}px, ${translate.y}px)`,
              transition: 'transform 0.1s',
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default Services;
